<template>
  <div class="case-detail-container">
    <!-- navbar -->
    <qb-navbar title="案例展示"> </qb-navbar>
    <!-- title -->
    <qb-title title="案例展示" subtitle="Quanbang Group Case show"></qb-title>

    <h3 class="title">
      智囊团分享 | 如何不花钱撬动产业，成为全国以及全球的行业第一
    </h3>
    <div class="line">
      <div class="adorn"></div>
    </div>
    <div class="video">
      <video
        src="https://0.rc.xiniu.com/g3/M00/08/98/CgAH513DbbOAAIpsAawewRzESN4234.mp4"
        poster="@/assets/images/video_cover.png"
        loop="loop"
        ref="video"
        :controls="controls"
        @click="handlePlay(false)"
        class="case-video"
      ></video>
      <div class="play_btn" @click="handlePlay(true)" v-if="showPlayBtn">
        <img src="@/assets/images/fission/play_btn.png" alt="" />
      </div>
    </div>
    <div class="content" v-html="content"></div>
    <div class="bottom-line"></div>
    <div class="time">2020-06-30</div>
  </div>
</template>

<script>
export default {
  name: 'CaseDetail',
  created() {
    console.log(this.$route.params.id)
  },
  data() {
    return {
      showPlayBtn: true,
      controls: false,
      content: `我创业13年一路走来起起伏伏，有的产业成功了，有的以失败收场，尤其是在2014年，一年的时间关掉三家公司， 终于让我意识到学习的重要性。于是我开始四处游学，求取真经，寻求变革之法。<br />
                在我来到泉邦之前，那一年我花了130万的学费去到北上广深一共18次，也报了当下很多所谓名师的课程，但是慢慢发现从刚开始学习的兴奋、激动，期待到后来越学越迷茫，因为老师讲的形式多，理论多，难落地。对于老板来说，时间成本远远大于学费成本，更怕的是学到一知半解，运用了错误的知识，让企业发展成相反的方向。<br />
                我花了580万元，整合这个行业最顶尖的机关密室的人才，但是苦苦经营了一年都没有实现盈利，非常苦恼。在2017年的11月份，我结缘泉邦，当时听完了课程，发现马老师教的和其他老师教的还真不一样，马老师教会我们如何不花钱，去撬动一个产业，如何从顶层设计出发，去融合更多的人才和渠道。半个月之后，马老师带着团队去到我们公司，给我们公司的高管开了整整一天的会议，帮我们制定了2018年的裂变计划。利用这套方案，一年不到的时间，我们成功招商了40多家城市代理和将近70个城市合伙人，收款人民币1300万元，同时也成为这个行业的全国第一以及全球第一。<br />
                在今年的7月份去到丽江游学，我又和马老师用了很长时间的探讨、沟通、推敲，重新帮我们制定了2019年的战略，做了一个升级，我们应该是打造了全中国第一个智能全屋定制，以及开创了这个行业绝无仅有的三位一体的商业模式。<br />
                我现在的目标是先进中国100名，年营收过亿，当我做到全国第20名的时候，我上市的梦想基本上也就实现了。所以特别庆幸两年前我和泉邦这次合作和马老师的合作，非常的感恩马老师，我们更收获了彼此一辈子的友谊。每次游学，不管多忙，我都会去参加，因为跟随马老师游学，每次都不虚此行，收获满满。<br />`
    }
  },
  methods: {
    handlePlay(flag) {
      if (flag) {
        this.$refs.video.play()
        this.controls = true
        this.showPlayBtn = false
        return
      }
      this.$refs.video.pause()
      this.showPlayBtn = true
      this.controls = false
    }
  }
}
</script>

<style lang="less" scoped>
.case-detail-container {
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #010343;
    line-height: 25px;
    padding: 42px 20px 2px;
  }
  .line {
    position: relative;
    width: 375px;
    height: 2px;
    background: #010343;

    .adorn {
      position: absolute;
      top: -2px;
      right: 20px;
      width: 52px;
      height: 4px;
      background: #010343;
    }
  }
  .video {
    position: relative;
    width: 335px;
    height: 188px;
    margin: 42px auto;
    .case-video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .play_btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 26px;
      height: 26px;
    }
  }
  .content {
    padding: 0 20px;
    font-size: 16px;
    font-weight: 500;
    color: #010343;
    line-height: 34px;
  }
  .bottom-line {
    width: 335px;
    height: 1px;
    background: #010343;
    opacity: 0.49;
    margin: 5px auto 0px;
  }
  .time {
    padding-left: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #010343;
    line-height: 32px;
    opacity: 0.49;
    margin-bottom: 5px;
  }
}
</style>
